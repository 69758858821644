import type { FC } from 'react'
import React, { createContext, useContext } from 'react'
import { PageTypes } from 'src/typings/pageTypes'

export type PageTypeContext = {
  pageType: PageTypes
}

const PageTypeContext = createContext({} as PageTypeContext)

export const PageTypeProvider: FC<
  React.PropsWithChildren<{ pageType: PageTypes }>
> = ({ children, pageType }) => {
  return (
    <PageTypeContext.Provider value={{ pageType }}>
      {children}
    </PageTypeContext.Provider>
  )
}
export const usePageTypeContext = () => {
  return useContext(PageTypeContext)
}
