import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

export const useApplicationInsightsAPI = () => {
  const appInsights = useAppInsightsContext()

  const trackCustomEvent = (data: any, name: string) => {
    appInsights.trackEvent({ name: name }, data)
  }

  return { trackCustomEvent }
}
