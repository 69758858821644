import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import axios from 'axios'
import { getIsPrivateLabel } from 'src/components/cart/CartSidebar/CartSidebar'
import {
  getSessionFavouriteStore,
  getSessionFavouriteStorePPData,
} from 'src/components/sections/Store/utilities'
import { getSessionIsVet } from 'src/components/sections/UserAccount/utilities'
import * as storeConfig from '../../../store.config'
import { useStoreAPI } from '../store/useStoreAPI'
import { useCart } from './useCart'
import { usePageTypeContext } from 'src/contexts/page-type-context'
import { useApplicationInsightsAPI } from '../insights/useApplicationInsightsAPI'

const { checkoutUrl } = storeConfig

export const updateOrderForm = async (items: any, cartId: string) => {
  const orderFormLoyaltyArray: Array<string> = []

  items.forEach(async (item: any) => {
    orderFormLoyaltyArray.push(
      `${item?.itemOffered?.sku}-${getIsPrivateLabel(item)}`
    )
  })

  const privateLabelArray = orderFormLoyaltyArray.join(',')

  await axios.post('/api/checkout/updateOrderForm', {
    orderFormId: cartId,
    appId: 'arcaplanetskuattributes',
    privateLabelArray: privateLabelArray,
  })
}

export const useCheckoutButton = () => {
  const { isValidating: isCartValidating, id, items, total } = useCart()
  const {
    currency: { code },
    person,
  } = useSession()

  const isVet = getSessionIsVet()

  const { setShippingData } = useStoreAPI()
  const { trackCustomEvent } = useApplicationInsightsAPI()
  const { pageType } = usePageTypeContext()

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    const eventItems = items.map((item) => {
      const itemCategory = item.itemOffered.breadcrumbList?.itemListElement
        .slice(0, -1)
        .map((x) => x.name)

      const isKit = item.itemOffered?.stockKeepingUnit?.isKit

      return {
        item_id: item.itemOffered.isVariantOf.productGroupID,
        item_name: item.itemOffered.isVariantOf.name,
        item_brand: item.itemOffered.brand.name,
        item_variant: item.itemOffered.sku,
        item_category: itemCategory,
        item_list_name: itemCategory.join(' '),
        item_list_id: itemCategory.join(' '),
        index: 0,
        quantity: item.quantity,
        price: item.price,
        discount: +(item.listPrice - item.price).toFixed(2),
        full_price: item.listPrice,
        discount_percentage: +(1 - item.price / item.listPrice).toFixed(2),
        currency: code as CurrencyCode,
        item_variant_name: item.itemOffered.name,
        product_reference_id: Number(item.itemOffered.gtin),
        coupon: null,
        // Adding optional kit parameters
        ...(isKit && {
          bundle_label: item.itemOffered.isVariantOf.name,
          bundle_discount: +(item.listPrice - item.price).toFixed(2),
          bundle_discount_percentage: +(
            1 -
            item.price / item.listPrice
          ).toFixed(2),
        }),
      }
    })

    const kitItems = items.filter(
      (item) => item.itemOffered?.stockKeepingUnit?.isKit
    )
    const hasKits = kitItems?.length > 0
    const hasMoreThanOneKit = kitItems?.length > 1
    const kitItemsMapped = kitItems?.map((item) => ({
      bundle_label: item.itemOffered?.isVariantOf?.name,
      bundle_discount: hasMoreThanOneKit
        ? (item.listPrice - item.price).toFixed(2)
        : +(item.listPrice - item.price).toFixed(2),
      bundle_discount_percentage: hasMoreThanOneKit
        ? (1 - item.price / item.listPrice).toFixed(2)
        : +(1 - item.price / item.listPrice).toFixed(2),
    }))
    const formattedKitInfo = hasMoreThanOneKit
      ? kitItemsMapped.reduce(
          (
            acc: Record<string, string>,
            curr: Record<string, string | number>
          ) => {
            for (const prop of Object.keys(curr)) {
              if (!acc.hasOwnProperty(prop)) {
                acc[prop] = curr[prop].toString()
              } else {
                acc[prop] = acc[prop].concat(` | ${curr[prop]}`)
              }
            }
            return acc
          },
          {}
        )
      : kitItemsMapped[0]

    sendAnalyticsEvent<UnknownEvent>({
      name: 'begin_checkout',
      params: {
        currency: code as CurrencyCode,
        value: total,
        coupon: null,
        orderFormId: id,
        // Adding optional kit parameters
        ...(hasKits && formattedKitInfo),
        items: eventItems,
      },
    })

    const favouriteStore = getSessionFavouriteStore()
    const pickupPointData = getSessionFavouriteStorePPData()

    if (favouriteStore?.id && pickupPointData) {
      await setShippingData(id, pickupPointData)
    }

    if (!isVet) await updateOrderForm(items, id)

    if (!isCartValidating && id) {
      try {
        trackCustomEvent(
          {
            userId: person?.email,
            cartId: id,
            pageType: pageType,
            actionTrigger: 'begin-checkout-button',
          },
          'begin-checkout'
        )
      } catch (er) {
        console.log(er)
      }

      window.location.href = `${checkoutUrl}?orderFormId=${id}#/orderform`
    }
  }

  return {
    onClick,
    disabled: isCartValidating,
    'data-testid': 'checkout-button',
  }
}
