import {
  ApplicationInsights,
  PageView,
  PageViewPerformance,
} from '@microsoft/applicationinsights-web'

import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'
import { PageTypes } from 'src/typings/pageTypes'

export const ApplicationInsightsService = (pageType?: PageTypes) => {
  const reactPlugin = new ReactPlugin()
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.GATSBY_APPINSIGHTSKEY ?? '',
      enableAutoRouteTracking: false,
      disableAjaxTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: globalHistory },
      },
    },
  })

  const customTelemetryProcessor = (envelope: any) => {
    const telemetryItem = envelope?.baseData
    if (
      (envelope.name === PageView.envelopeType ||
        envelope.name === PageViewPerformance.envelopeType) &&
      telemetryItem
    ) {
      telemetryItem.name = pageType
    }
  }

  appInsights.addTelemetryInitializer((envelope) =>
    customTelemetryProcessor(envelope)
  )

  appInsights.loadAppInsights()

  return { appInsights, reactPlugin }
}
