import { ArrowDown } from 'src/components/icons/ArrowDown'

export const mockMenuCane = [
  {
    id: 85,
    name: 'Cane',
    code: 'cane',
    url: '/cane',
    image: <ArrowDown />,
    children: [
      {
        id: 86,
        name: 'First column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Cibo secco',
            url: '/cane/cibo-secco',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Cibo umido',
            url: '/cane/cibo-umido',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Snack',
            url: '/cane/snack',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Diete cibo secco',
            url: '/cane/diete-cibo-secco',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Diete cibo umido',
            url: '/cane/diete-cibo-umido',
            image: '',
            children: [],
          },
        ],
      },
      {
        id: 86,
        name: 'Second column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Accessori',
            url: '/cane/accessori',
            image: '',
            children: [
              {
                id: 88,
                name: 'Abbigliamento',
                url: '/cane/accessori/abbigliamento',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Addestramento',
                url: '/cane/accessori/addestramento',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Contenitori cibo',
                url: '/cane/accessori/ciotole-e-dispenser',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Toelettatura',
                url: '/cane/accessori/toelettatura',
                image: '',
                children: [],
              },
            ],
          },
          {
            id: 87,
            name: 'Giochi',
            url: '/cane/giochi',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Guinzaglieria',
            url: '/cane/guinzaglieria',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Trasportini e viaggio',
            url: '/cane/trasportini-e-viaggio',
            image: '',
            children: [],
          },
          {
            id: 87,
            name: 'Cucce e lettini',
            url: '/cane/cucce-e-lettini',
            image: '',
            children: [],
          },
        ],
      },
      {
        id: 86,
        name: 'Third column',
        url: '',
        image: '',
        isVisible: false,
        children: [
          {
            id: 87,
            name: 'Igiene',
            url: '/cane/igiene',
            image: '',
            children: [
              {
                id: 88,
                name: 'Igiene manto e cute',
                url: '/cane/igiene/manto-e-cute',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Igiene orale',
                url: '/cane/igiene/orale',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Pannolini',
                url: '/cane/igiene/pannolini',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Sacchetti igienici',
                url: '/cane/igiene/sacchetti-igienici',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Porta sacchettini',
                url: '/cane/igiene/portasacchetti',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Traversine igieniche',
                url: '/cane/igiene/traversine-igieniche',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Salviette',
                url: '/cane/igiene/salviette',
                image: '',
                children: [],
              },
            ],
          },
          {
            id: 87,
            name: 'Antiparassitari e curativi',
            url: '',
            image: '',
            children: [
              {
                id: 88,
                name: 'Antiparassitari',
                url: '/cane/antiparassitari-e-curativi/antiparassitari',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Insettorepellenti',
                url: '/cane/antiparassitari-e-curativi/insettorepellenti',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Alimenti complementari',
                url: '/cane/antiparassitari-e-curativi/integratori',
                image: '',
                children: [],
              },
              {
                id: 88,
                name: 'Parafarmacia',
                url: '/cane/antiparassitari-e-curativi/parafarmacia',
                image: '',
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
]
