import { PageTypes } from 'src/typings/pageTypes'

const getDynamicPageType = (page: string, path: string) => {
  let result = PageTypes.NotSet

  if (
    page?.startsWith('component---src-pages-volantino') ||
    (page === 'component---src-templates-fullscreen-tsx' &&
      path === '/volantino-mese')
  ) {
    result = PageTypes.Volantino
  }

  return result
}

export const getPageType = (page: string, path: string) => {
  let result: PageTypes = PageTypes.NotSet

  switch (page) {
    case 'component---src-pages-slug-tsx':
    case 'component---src-templates-plp-tsx':
      result = PageTypes.PLP
      break
    case 'component---src-pages-lp-tsx':
      result = PageTypes.InstitutionalPage
      break
    case 'component---src-pages-index-tsx':
      result = PageTypes.Home
      break
    case 'component---src-pages-[slug]-p-tsx':
      result = PageTypes.PDP
      break
    case 'component---src-pages-login-tsx':
      result = PageTypes.Login
      break
    case 'component---src-pages-logout-tsx':
      result = PageTypes.Logout
      break
    case 'component---src-pages-checkout-tsx':
      result = PageTypes.Checkout
      break
    case 'component---src-pages-account-tsx':
      result = PageTypes.Account
      break
    case 'component---src-pages-s-tsx':
      result = PageTypes.Search
      break
    case 'component---src-templates-brand-tsx':
      result = PageTypes.Brand
      break
    case 'component---src-templates-booxie-mobile-page-tsx':
      result = PageTypes.Booxie
      break
    case 'component---cache-dev-404-page-js':
      result = PageTypes.PageNotExist
      break
    default:
      result = getDynamicPageType(page, path)
      break
  }

  return result
}
