export const buildCustomEvent = (
  item: any,
  email: string | null | undefined,
  cartId: string,
  pageType: string,
  actionTrigger: string
) => {
  return {
    userId: email,
    cartId: cartId,
    productId: item.itemOffered.isVariantOf.productGroupID,
    sku: item.itemOffered.sku,
    url: location.href,
    pageType: pageType,
    actionTrigger: actionTrigger,
  }
}
